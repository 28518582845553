/*-----------------
	11. Header
-----------------------*/

.nav-transparent {
  background-color: transparent !important;
  border-bottom: 0 !important;
}

.header-nav {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: baseline;
  background-color: #fff;
  border: 0;
  border-bottom: 1px solid #f0f0f0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  position: relative;
  height: 85px;
  padding: 0 30px;
  margin-bottom: 0;
  align-items: center;
}

.main-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.main-nav > li.active > a {
  color: #09dca4;
}

.main-nav > li .submenu li a {
  display: block;
  padding: 10px 15px;
  clear: both;
  white-space: nowrap;
  font-size: 14px;
  color: #2d3b48;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
  width: 100%;
  border-top: 1px solid #f0f0f0;
}

.main-nav > li .submenu > li.has-submenu > a::after {
  content: "\f054";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  position: absolute;
  right: 15px;
  top: 12px;
  font-size: 13px;
}

.main-nav > li .submenu li {
  position: relative;
}

.main-nav li a {
  display: block;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  background-color: transparent;
  padding-left: 20px;
}

.main-nav li.login-link {
  display: none;
}

.logo {
  display: inline-block;
  margin-right: 30px !important;
  width: 160px;
}

.header-contact-img {
  display: inline-block;
}

.header-contact-img i {
  color: #be141b;
  font-size: 30px;
}

.header-contact-detail {
  display: inline-block;
  padding-left: 10px;
}

.header-contact-detail p.contact-header {
  color: #be141b;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 2px;
  text-align: left;
}

.header-contact-detail p.contact-info-header {
  color: #000;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 0;
}

.header-navbar-rht {
  margin: 0;
  margin-left: auto;
  padding: 0;
}

.header-navbar-rht li {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding-right: 20px;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
}

.header-navbar-rht li:last-child {
  padding-right: 0px;
}

.header-navbar-rht li .dropdown-menu {
  border: 0;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
}

.header-navbar-rht .dropdown-toggle::after {
  display: none;
}

.header-navbar-rht li .dropdown-menu::before {
  content: "";
  position: absolute;
  top: 2px;
  right: 0;
  border: 7px solid #fff;
  border-color: transparent transparent #ffffff #ffffff;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  box-shadow: -2px 2px 2px -1px rgba(0, 0, 0, 0.1);
}

.header-navbar-rht li .dropdown-menu .dropdown-item {
  border-top: 1px solid #f0f0f0;
  padding: 10px 15px;
}

.header-navbar-rht li .dropdown-menu .dropdown-item:first-child {
  border-top: 0;
  border-radius: 5px 5px 0 0;
}

.header-navbar-rht li .dropdown-menu .dropdown-item:last-child {
  border-radius: 0 0 5px 5px;
}

.header-navbar-rht li a.header-login:hover {
  background-color: #be141b;
  border-color: #be141b;
  color: #fff;
}

.header-navbar-rht li .dropdown-menu a:hover {
  color: #09dca4;
  letter-spacing: 0.5px;
  padding-left: 20px;
  background-color: #fff;
}

.header-navbar-rht li a.header-login {
  border: 2px solid #be141b;
  border-radius: 4px;
  padding: 10px 15px !important;
  text-align: center;
  font-size: 15px;
  color: #be141b;
  text-transform: uppercase;
  font-weight: 500;
}

.header .has-arrow .dropdown-toggle:after {
  border-top: 0;
  border-left: 0;
  border-bottom: 2px solid #757575;
  border-right: 2px solid #757575;
  content: "";
  height: 8px;
  display: inline-block;
  pointer-events: none;
  -webkit-transform-origin: 66% 66%;
  -ms-transform-origin: 66% 66%;
  transform-origin: 66% 66%;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  width: 8px;
  vertical-align: 2px;
  margin-left: 10px;
}

.header .has-arrow .dropdown-toggle[aria-expanded="true"]:after {
  -webkit-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.user-menu {
  float: right;
  margin: 0;
  position: relative;
  z-index: 99;
}

.user-menu.nav > li > a {
  color: #fff;
  font-size: 14px;
  line-height: 58px;
  padding: 0 15px;
  height: 60px;
}

.user-menu.nav > li > a:hover,
.user-menu.nav > li > a:focus {
  background-color: rgba(0, 0, 0, 0.2);
}

.user-menu.nav > li > a:hover i,
.user-menu.nav > li > a:focus i {
  color: #fff;
}

.user-img {
  display: inline-block;
  position: relative;
}

.header-contact-detail p.contact-info-header.white-font {
  color: #fff;
}

.user-img > img {
  height: 31px;
  object-fit: cover;
  width: 31px;
}

.user-menu.nav > li > a.mobile_btn {
  border: 0;
  position: relative;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.header-navbar-rht .dropdown-menu {
  min-width: 200px;
  padding: 0;
}

.header-navbar-rht .dropdown-menu .dropdown-item {
  padding: 7px 15px;
}

.header-navbar-rht .dropdown-menu .dropdown-item {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-top: 1px solid #e3e3e3;
  padding: 10px 15px;
}

.header-navbar-rht .dropdown-menu .dropdown-item:hover {
  color: #09dca4;
}

.user-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 10px 15px;
}

.user-header .user-text {
  margin-left: 10px;
}

.user-header .user-text h6 {
  font-size: 15px;
  margin-bottom: 2px;
}

.header-navbar-rht .logged-item .nav-link {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 14px;
  line-height: 85px;
  padding: 0 10px;
}

.view-cart-header a {
  position: relative;
  font-size: 20px;
}

.unread-msg1 {
  background-color: #be141b;
  border-radius: 2px;
  color: #be141b;
  font-size: 10px;
  font-style: normal;
  padding: 0 5px;
  position: absolute;
  right: 20px;
  text-align: center;
  top: -10px;
  right: -10px;
}

.header-top {
  display: flex;
  background: #be141b;
  color: #fff;
  padding: 8px 30px;
}

.header-top ul {
  display: flex;
  margin: 0;
  list-style: none;
  padding: 0;
}

.header-top ul li {
  padding-right: 25px;
  font-size: 13px;
}

.header-top ul li:last-child {
  padding-right: 0;
}

.header-top .right-top {
  margin-left: auto;
}

.top-icon {
  margin-right: 5px;
}

.search-box-1 {
  background: #be141b;
  padding: 40px 20px;
  border-radius: 10px;
  margin-top: -50px;
  position: absolute;
}

.search-box .form-group {
  margin-bottom: 0px;
}

.navBar {
  align-items: center;
}
.navbar-brand.logo {
  width: 100%;
  text-align: center;
  margin-right: 0;
}
.navbar-brand.logo img {
  height: 60px;
}

a {
  color: #2e3842;
}

a:hover {
  color: #be141b;
}
