.error-box {
    margin:10px auto;
    /* max-width: 480px; */
    padding:3rem 0;
    text-align: center;
    width: 100%;
    background: #fff;
    /* box-shadow: 0 0 5px #ccc; */
    /* background: url(../../../assets/img/login-banner.png) no-repeat center; */
}
.error-box h1 {
    font-size: 10em;
    font-weight:600;
}
.error-box p {
    margin-bottom: 1.875rem;
    color: #1f1f1f;
}
/* .error-box h3{
    color: #1f1f1f;
} */
.error-box .btn {
    border-radius: 50px;
    font-size: 18px;
    font-weight: 600;
    min-width: 200px;
    padding: 10px 20px;
}