.modal-class {
  width: 0px !important;
  height: 0px !important;
  margin-top: 25%;
}


@media only screen and (max-width: 575.98px) {
.modal-class {
  width: 100% !important;
  height: 80% !important;
  }
}