.timePickerClass {
  display: flex !important;
  border: solid;
  border-color: lightgray !important;
  border-width: 1px;
  border-radius: 4px;
}
.rc-time-picker-input {
  border-width: 0px !important;
}
